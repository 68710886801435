<template>
  <div class="crafting-produce">
    <CraftingDescriptionPopup
      v-if="$device.isMobile && isModalOpen"
      :title="$t('craftingAvailable')"
      @toggle="handleToggle"
    >
      <CraftingQueueLimitContent />
    </CraftingDescriptionPopup>
    <div ref="craftingProduceContentRef" class="crafting-produce-content">
      <div v-if="recipeSpeedTranslation" class="crafting-produce-name">
        {{ $t(tokenMetadata?.label, { count: 0 }) }}
        <UIChevron :type="ChevronType.Warning" :text="recipeSpeedTranslation" />
      </div>
      <div v-else class="crafting-produce-name">
        {{ $t(`${recipeIdPrefix}${currentRecipe?.recipeId}`) }}
      </div>

      <div class="crafting-info" @click="handleToggle">
        <div class="crafting-recipes-counter">
          <span>{{ $t('craftingAvailableRecipes') }}:</span>
          <div class="crafting-recipes-counter-value">
            <el-skeleton :loading="isLoading || recipeRequestStatus === 'pending'" animated style="max-height: 17px">
              <template #template>
                <el-skeleton-item style="width: 30px; height: 18px" />
              </template>

              <template #default>
                <span
                  class="crafting-default-color"
                  :class="{
                    'crafting-danger-color': notEnoughRecipesForCrafting
                  }"
                >
                  {{ maxAmountAvailableForCraft }}
                </span>
              </template>
            </el-skeleton>
          </div>
        </div>

        <span class="divider-vertical">|</span>
        <hr class="divider-horizontal" />

        <div class="crafting-queue-limit">
          <span>{{ $t('craftingQueueLimit') }}:</span>

          <div class="crafting-queue-limit-value">
            <el-skeleton
              :loading="isLoading || recipeRequestStatus === 'pending' || extraDelay"
              animated
              style="max-height: 17px"
            >
              <template #template>
                <el-skeleton-item style="width: 40px; height: 18px" />
              </template>

              <template #default>
                <div
                  class="crafting-default-color"
                  :class="{
                    'crafting-danger-color': queueLimitFilled,
                    blink: isAnimatedBlink && queueLimitFilled
                  }"
                >
                  <CraftingQueueLimitCountDown ref="queueLimitCountDownRef" :time="queueTimeLimit" />
                </div>
              </template>
            </el-skeleton>
          </div>

          <el-tooltip
            trigger="hover"
            popper-class="popper-default"
            :disabled="$device.isMobile"
            placement="right-start"
          >
            <template #content>
              <CraftingQueueLimitContent />
            </template>
            <InfoIcon class="info-icon" />
          </el-tooltip>
        </div>
      </div>

      <div class="crafting-tabs">
        <div
          class="crafting-tabs-tab"
          :class="selectedTab === CraftingItemTab.RECIPE && 'active'"
          @click="() => handleClickTab(CraftingItemTab.RECIPE)"
        >
          <span>{{ $t('craftingTabName1') }}</span>
          <span class="countdown">(<CraftingRecipeTimeCountDown :time="craftingRecipeTime" />)</span>
        </div>
        <div
          class="crafting-tabs-tab"
          :class="selectedTab === CraftingItemTab.CRAFTING && 'active'"
          @click="() => handleClickTab(CraftingItemTab.CRAFTING)"
        >
          <span>{{ $t('craftingTabName2') }}</span>
          <span class="countdown">(<CraftingQueueTimeCountDown :time="queueTimeInitial" />)</span>
        </div>
      </div>
      <CraftingRecipe
        v-if="selectedTab === CraftingItemTab.RECIPE && currentRecipe"
        :recipe-id="currentRecipe.recipeId"
      />
      <CraftingRunningRecipes
        v-if="selectedTab === CraftingItemTab.CRAFTING && currentRecipe"
        :token-metadata="props.tokenMetadata"
        :recipe-id="currentRecipe?.recipeId"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, inject, ref } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { CraftingQueueStatusMessage, QueueTypes, RecipeType, type TTokensConfigItem } from '~/utils/constants';
import { type BuildingProps, CraftingItemTab, type RecipeProps, type RunningRecipeProps } from '~/types/crafting';
import useEnvs from '~/composables/useEnvs';
import { useMainStore } from '~/stores/mainStore';
import CraftingRecipeTimeCountDown from '~/components/Crafting/CraftingRecipeTimeCountDown.vue';
import { BigNumber } from 'bignumber.js';
import CraftingQueueTimeCountDown from '~/components/Crafting/CraftingQueueTimeCountDown.vue';
import { useRuntimeConfig } from 'nuxt/app';
import InfoIcon from '~/components/icons/InfoIcon.vue';
import CraftingQueueLimitContent from '~/components/Crafting/CraftingQueueLimitContent.vue';
import { ZeroHash } from 'ethers';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { ChevronType } from '~/utils/constants/ui';

const { t } = useI18n();
const { address } = useWeb3ModalAccount();
const config = useRuntimeConfig();
const { apiUrl } = useEnvs();
const { isMobile } = useDevice();
const route = useRoute();
const store = useMainStore();
const craftingProduceContentRef = ref();
const isAnimatedBlink = ref(false);
const [isModalOpen, toggleModal] = useToggle();
const queueLimitCountDownRef = ref();

const selectedTab = ref(CraftingItemTab.RECIPE);
const recipeIdPrefix = computed(() => (config?.public.env === 'prod' ? 'prodRecipeId' : 'testnetRecipeId'));

const props = defineProps<{
  tokenMetadata: TTokensConfigItem;
  buildingKey: string;
  parentElementRef: HTMLElement | null;
}>();

const toggleModalQueueTimeInjected = inject<() => void>('toggleModalQueueTime');
const currentRecipe = inject<Ref<RecipeProps>>('currentRecipe');
const building = inject<Ref<BuildingProps>>('building');
const extraDelay = ref(false);

// reset crafting table height
watch(extraDelay, () => {
  if (extraDelay.value && props.parentElementRef) {
    props.parentElementRef.style.setProperty('height', 'auto');
  }
});

const { maxAmountAvailableForCraft, isLoading, isEnoughTokens } = useMaxAmountAvailableForCraft();
provide('maxAmountAvailableForCraft', maxAmountAvailableForCraft);
provide('isMaxAmountAvailableForCraftLoading', isLoading);
provide('isEnoughTokens', isEnoughTokens);

const { data: recipes } = useNuxtData<RecipeProps[]>(
  `buildingRecipes${isMobile && route.name !== 'districts' ? building?.value.buildingHash : ''}`
);

const computedRecipeId = computed(() => currentRecipe?.value.recipeId);

const { data: runningRecipes, refresh: refreshRunningRecipes } = useFetch<RunningRecipeProps[]>(
  () => `/crafting/recipes/in-queue`,
  {
    baseURL: apiUrl,
    watch: [() => store.notificationUpdateVersion, () => building?.value.buildingHash, computedRecipeId],
    query: {
      recipeId: computedRecipeId,
      buildingHash: building?.value.buildingHash || ZeroHash
    },
    key: 'recipes/in-queue',
    onRequest: () => {
      queueLimitCountDownRef.value?.abortCountDown();
      queueTime.value = 0;
    },
    transform: (data) => {
      if (currentRecipe && currentRecipe.value.queueType === QueueTypes.COMMON) return data;

      return data.filter((recipe) => recipe.userAddress === address.value);
    }
  }
);

const { data: recipeToCraft, status: recipeRequestStatus } = useFetch<RecipeProps>(
  () => `/crafting/recipes/${currentRecipe?.value.recipeId}`,
  {
    watch: [() => store.notificationUpdateVersion, computedRecipeId],
    baseURL: apiUrl,
    key: 'recipes/recipe',
    onResponse: async () => {
      // Extra delay for ensuring that the queueTime has been recalculated first
      //  to avoid the redundant UI updates (of queueTimeLimit)
      extraDelay.value = true;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      extraDelay.value = false;
    }
  }
);

onMounted(() => {
  refreshRunningRecipes();
});

const craftingRecipeTime = computed(() => (recipeToCraft.value?.duration || 0) * 1000);

const lastRunningRecipeEndTime = computed(
  () => Math.max(...(runningRecipes.value?.map((rec) => new Date(rec.craftEndTime).getTime()) || [])) || 0
);

const queueTimeInitial = computed(() => {
  if (BigNumber(store.notificationUpdateVersion).isNaN()) return 0;
  return BigNumber(lastRunningRecipeEndTime.value - Date.now()).isPositive()
    ? lastRunningRecipeEndTime.value - Date.now()
    : 0;
});

const queueTime = ref(
  BigNumber(lastRunningRecipeEndTime.value - Date.now()).isPositive() ? lastRunningRecipeEndTime.value - Date.now() : 0
);

const notEnoughRecipesForCrafting = computed(() => maxAmountAvailableForCraft.value === 0);

const queueTimeLimit = computed(() => {
  if (BigNumber(store.notificationUpdateVersion).isNaN()) return 0;
  return BigNumber(recipeToCraft.value?.duration || 0)
    .multipliedBy(1000)
    .multipliedBy(recipes.value?.find((recipe) => recipe.recipeId === recipeToCraft.value?.recipeId)?.maxQueue || 0)
    .minus(queueTime.value)
    .toNumber();
});

const { messageType } = useQueueLimitStatus(recipeToCraft, queueTimeLimit, maxAmountAvailableForCraft);

const queueLimitFilled = computed(() => {
  const isInstantRecipe = currentRecipe?.value && currentRecipe.value.duration === 0;

  return (
    !maxAmountAvailableForCraft.value &&
    isEnoughTokens.value &&
    !isLoading.value &&
    !isInstantRecipe &&
    messageType.value &&
    messageType.value !== CraftingQueueStatusMessage.PERSONAL_QUEUE_OVERLOAD
  );
});

const updateQueueTime = () => {
  const isPositive = BigNumber(lastRunningRecipeEndTime.value - Date.now()).isPositive();
  if (!isPositive) {
    queueTime.value = 0;
    return;
  }

  queueTime.value = lastRunningRecipeEndTime.value - Date.now();
};

const setQueueTime = (value: number) => {
  queueTime.value = value;
};

const handleClickTab = (tab: CraftingItemTab) => {
  selectedTab.value = tab;
};

const recipeSpeedTranslation = computed(() => {
  const recipeNameEN = t(
    `${recipeIdPrefix.value}${currentRecipe?.value?.recipeId}`,
    {},
    {
      locale: 'en'
    }
  );

  if (recipeNameEN.toLocaleLowerCase().includes(RecipeType.SUPERFAST)) {
    return t('craftingSpeedSuperFast');
  }

  if (recipeNameEN.toLocaleLowerCase().includes(RecipeType.FAST)) {
    return t('craftingSpeedFast');
  }

  return '';
});

const handleToggle = () => {
  toggleModalQueueTimeInjected && toggleModalQueueTimeInjected();
  toggleModal();
};

const animateBlink = useDebounceFn(() => {
  isAnimatedBlink.value = true;

  setTimeout(() => {
    isAnimatedBlink.value = !isAnimatedBlink.value;
  }, 3000);
}, 1000);

provide('recipeRequestStatus', recipeRequestStatus);
provide('lastRunningRecipeEndTime', lastRunningRecipeEndTime);
provide('queueTime', queueTime);
provide('queueTimeLimit', queueTimeLimit);
provide('updateQueueTime', updateQueueTime);
provide('setQueueTime', setQueueTime);
provide('animateBlink', animateBlink);
provide('isAnimatedBlink', isAnimatedBlink);
</script>

<style lang="scss">
.crafting {
  &-tabs {
    display: flex;
    width: 100%;
    box-shadow: 2px 4px 5px 0px #00000040;
    min-height: 55px;
    user-select: none;

    &-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 100%;
      background: radial-gradient(59.02% 71.08% at 84.01% 85.82%, #040f14 0%, #081b25 100%);
      font-size: 20px;
      font-family: Grenze;
      line-height: 29.6px;
      text-align: center;
      color: #fff;
      border: 1px solid #1e353e;
      flex-direction: column;

      &.active {
        background: linear-gradient(180deg, #0083ad 0%, #00354f 100%);
      }

      .countdown {
        font-family: Eczar;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        text-align: center;

        color: rgba(255, 255, 255, 0.5);

        span {
          display: inline-flex;
        }
      }
    }
  }

  &-info {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding-top: 4px;
    padding-bottom: 10px;

    font-family: Eczar;
    font-weight: 500;
    font-size: 16px;
    line-height: 17.6px;
    text-align: center;
    color: rgba(255, 255, 255, 1);

    @media screen and (max-width: 900px) {
      gap: 0;
      flex-direction: column;
    }

    .divider-vertical {
      color: #1e353e;
      display: block;

      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    hr.divider-horizontal {
      width: 32px;
      border-color: #1e353e;
      display: none;

      @media screen and (max-width: 900px) {
        display: block;
      }
    }

    .crafting-default-color {
      color: #49ddaa;
    }

    .crafting-danger-color {
      color: #f6164c;
    }

    .crafting-queue-limit,
    .crafting-recipes-counter {
      display: flex;
      gap: 8px;

      @media screen and (max-width: 900px) {
        justify-content: center;
      }
    }

    .info-icon {
      cursor: pointer;
      height: 15px;
      width: 15px;
      max-height: 15px;
    }

    &-duration {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &-info-row {
    border-bottom: 2px solid #ffffff1a;
    padding: 10px;

    display: flex;
    justify-content: space-evenly;
    gap: 10px;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 5px;

      .timer-label {
        color: #90989d;
      }

      span {
        font-size: 20px;
        font-weight: 500;
        font-family: Grenze;
        margin-bottom: 0;
        color: #fff;
      }
    }

    &-time {
      font-family: Eczar;
      font-weight: 500;
      font-size: 20px;
      color: #fff;
      padding: 18px 0;

      &-ready {
        color: #49ddaa;
      }
    }
  }

  &-produce {
    background: #0d242f;
    border: 1px solid #1e353e;
    padding-top: 16px;
    padding-bottom: 16px;
    height: 100%;
    border-radius: 15px;

    .crafting-produce-content {
      min-height: 380px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &-name {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      color: #fff;
      font-weight: 700;
      font-family: Grenze;
      font-size: 26px;
      margin-bottom: 0;
      text-transform: capitalize;
      text-align: center;
    }

    &-time {
      margin-bottom: 20px;

      color: #fff;
      font-weight: 400;

      font-size: 20px;
    }

    @media screen and (max-width: 900px) {
      max-height: 100%;

      &-button {
        width: auto;
      }
    }
  }

  &-buttons {
    margin-top: 17px;
    padding: 0 16px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }

    & > * {
      margin-left: 10px;

      @media screen and (max-width: 900px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  .blink {
    display: flex;
    color: var(--error);
    animation:
      blink 3s linear,
      scale 2s ease-in;
  }

  @keyframes blink {
    0%,
    100% {
      color: var(--error);
    }
    50% {
      color: #ff144d;
    }
  }

  @keyframes scale {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.125);
    }
  }
}
</style>
