<template>
  <div
    class="crafting-wrapper"
    :class="{
      'max-height':
        isConfirmationOpen ||
        ($device.isMobile && isModalOpen) ||
        ($device.isMobile && isModalQueueTimeOpen) ||
        ($device.isMobile && isMaxHeight)
    }"
  >
    <CraftingConfirmScreen
      v-if="isConfirmationOpen && tokenMetadataComputed"
      :recipe="currentRecipe"
      :token-metadata="tokenMetadataComputed"
      :crafting-multiplier="craftingMultiplier"
    />
    <CraftingDescriptionPopup
      v-if="$device.isMobile && isModalOpen"
      :title="$t('craftingDescriptionTitle')"
      @toggle="toggleModal"
    >
      <CraftingModalInfoTooltip />
    </CraftingDescriptionPopup>

    <div class="crafting-container">
      <div class="crafting-header">
        <div class="crafting-header-icons" @click="toggleModal">
          <el-tooltip :disabled="$device.isMobile" placement="bottom-end" popper-class="popper-default">
            <template #content>
              <CraftingModalInfoTooltip />
            </template>
            <div class="crafting-header-icons-info" />
          </el-tooltip>
        </div>
        <div class="crafting-text">
          <h2 class="crafting-title">{{ $t(props.selectedBuilding.buildingKey) }}</h2>
          <p class="crafting-description">{{ $t(props.selectedBuilding.descriptionKey || '') }}</p>
        </div>
      </div>
      <div v-if="isCraftsAvailableExists" class="claimable-tokens">
        <CraftingReadyForClaimMaterials
          :claim-items="craftsAvailableForClaim"
          :token-metadata-computed="tokenMetadataComputed"
          :disabled="isClaiming"
          @claim-load-state-changed="(isLoading) => (isClaiming = isLoading)"
        />
      </div>

      <div class="crafting-content">
        <div
          :style="$device.isDesktop && { maxHeight: `${craftingTableElComputedHeight}px` }"
          class="crafting-available-craft"
        >
          <h2 class="crafting-available-craft-title">{{ $t('craftingRecipesTitle') }}</h2>
          <div class="crafting-available-craft-title-mobile">
            <img
              class="crafting-available-craft-title-mobile-arrow"
              :class="{ disabled: (scrollLeftRef || 0) <= 0 }"
              src="/img/icons/arrowRightThin.svg"
              alt="arrowLeft"
              @click="handleScrollRecipes(false)"
            />
            <h3>{{ $t('craftingChooseRecipe') }}</h3>
            <img
              class="crafting-available-craft-title-mobile-arrow"
              :class="{
                disabled:
                  scrollLeftRef >= (craftingListDOMRef?.scrollWidth || 0) - (craftingListDOMRef?.clientWidth || 0)
              }"
              src="/img/icons/arrowRightThin.svg"
              alt="arrowRight"
              @click="handleScrollRecipes(true)"
            />
          </div>
          <div
            ref="craftingListDOMRef"
            class="crafting-list"
            :class="{
              'crafting-list-max-height-auto': isMaxHeightAuto
            }"
            @scroll="(e) => (scrollLeftRef = (e?.target as HTMLElement)?.scrollLeft)"
          >
            <div
              v-for="(recipe, index) of recipes"
              :key="index"
              class="crafting-item"
              :class="[recipe?.recipeId === selectedRecipeId && 'selectedItem']"
              @click="handleSelectItemToCraft(recipe?.recipeId, index)"
            >
              <figure class="crafting-item-figure">
                <img :src="getIcon(Number(recipe.recipeId), recipe.products[0].tokenAddress)" alt="" />
              </figure>
              <h4
                class="crafting-item-name"
                :class="{ 'ru-locale-name': $i18n.locale === 'ru', 'de-locale-name': $i18n.locale === 'de' }"
              >
                {{ $t(`${recipeIdPrefix}${recipe?.recipeId}`) }}
              </h4>
            </div>
          </div>
        </div>
        <div ref="craftingTableElRef" class="crafting-table">
          <CraftingProduceItem
            v-if="tokenMetadataComputed && currentRecipe"
            :token-metadata="tokenMetadataComputed"
            :building-key="props.selectedBuilding.buildingKey"
            :recipe="currentRecipe"
            :parent-element-ref="craftingTableElRef"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, computed, provide, watch, onBeforeUnmount } from 'vue';
import { useRuntimeConfig } from 'nuxt/app';
import { BrowserProvider, Contract, ethers, getBytes, keccak256 } from 'ethers';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/vue';
import { ElNotification } from 'element-plus';
import { useMainStore } from '~/stores/mainStore';
import { BigNumber } from 'bignumber.js';
import { CraftingProduceItem, CraftingReadyForClaimMaterials, CraftingConfirmScreen } from '#components';
import useEnvs from '~/composables/useEnvs';
import useAbiAccess from '~/composables/useAbiAccess';
import type { TNullable } from '~/types/common';
import type {
  BuildingProps,
  UserActivityAvailableForClaim,
  ActiveUsersModalProps,
  RecipeProps,
  UnclaimedRecipeProps,
  CraftAvailableForClaim,
  RecipeProduct
} from '~/types/crafting';
import { tokenMetaData, modifyHistoryHashStatus, saveHashToLocalStorage } from '~/utils';
import useSendContractMethod from '~/composables/useSendContractMethod';
import { useI18n } from '#imports';
import abi from '@/public/abi/abi.json';
import { Tokens, TokenUniquenessType } from '~/utils/constants';
import CraftingModalInfoTooltip from '~/components/Crafting/CraftingModalInfoTooltip.vue';

const props = defineProps<{
  selectedBuilding: BuildingProps;
}>();

const { t } = useI18n();
const route = useRoute();
const { $getIconToken } = useNuxtApp();
const { getContract } = useAbiAccess();
const store = useMainStore();
const { walletProvider } = useWeb3ModalProvider();
const { address } = useWeb3ModalAccount();
const { sendContractMethod } = useSendContractMethod();
const { apiUrl, blockchain } = useEnvs();

const config = useRuntimeConfig();
const { isMobile } = useDevice();
const [isModalOpen, toggleModal] = useToggle();
const [isModalQueueTimeOpen, toggleModalQueueTime] = useToggle();
const isMaxHeight = ref(false);
const isMaxHeightAuto = ref(false);
const selectedRecipeId = ref<TNullable<string>>();

const craftingContract = await getContract('crafting', blockchain.contracts.crafting);

const userActivityRef = reactive<UserActivityAvailableForClaim[][]>([]);
const craftsAvailableForClaim = ref<CraftAvailableForClaim[]>([]);
const isConfirmationOpen = ref<boolean>(false);
const confirmationNumberRef = ref<number>(0);
const isItemCrafting = ref(false);
const craftingMultiplier = ref<number>(1);
const craftingTime = ref<string>('');
const craftingListDOMRef = ref<HTMLElement | null>(null);
const craftingTableElRef = ref<HTMLElement | null>(null);
const craftingTableElComputedHeight = ref<number>(craftingTableElRef.value?.clientHeight || 0);

const scrollLeftRef = ref<number>(0);
const socketRef = ref();
const isClaiming = ref(false);

// reset crafting table height
watch([selectedRecipeId, craftingTableElRef], () => {
  if (!craftingTableElRef.value) return;
  craftingTableElRef.value.style.setProperty('height', '420px');
});

useResizeObserver(craftingTableElRef, (entries) => {
  const entry = entries[0];
  const { height } = entry.contentRect;

  if (document.documentElement.clientWidth <= 900) {
    return;
  }

  craftingTableElComputedHeight.value = height;
});

const toggleMaxHeightCraftingModal = () => {
  isMaxHeight.value = !isMaxHeight.value;
};
const isCraftsAvailableExists = computed(() => craftsAvailableForClaim.value.length > 0);

provide('handleProduceItem', handleProduceItem);
provide('handleToggleConfirmationModal', handleToggleConfirmationModal);
provide('refreshList', refreshList);
provide('setProductMultiplier', setProductMultiplier);
provide('setCraftingTime', setCraftingTime);
provide('craftingMultiplier', craftingMultiplier);
provide('craftingTime', craftingTime);
provide('confirmationNumberRef', confirmationNumberRef);
provide('isItemCrafting', isItemCrafting);
provide('userActivity', userActivityRef);
provide('toggleModalQueueTime', toggleModalQueueTime);
provide('toggleMaxHeightCraftingModal', toggleMaxHeightCraftingModal);

const activeUsersModal = ref<ActiveUsersModalProps>({
  craftingViewersCount: 0
});
provide('activeUsersModal', activeUsersModal);

const { data: recipes } = useNuxtData<RecipeProps[]>(
  `buildingRecipes${isMobile && route.name !== 'districts' ? props.selectedBuilding.buildingHash : ''}`
);

watch(address, () => {
  refreshNuxtData(
    `buildingRecipes${isMobile && route.name !== 'districts' ? props.selectedBuilding.buildingHash : ''}`
  );
});

const { refresh: refreshUnclaimed } = await useFetch<UnclaimedRecipeProps[]>('/crafting/recipes/unclaimed', {
  baseURL: apiUrl,
  query: {
    userAddress: address
  },
  key: 'unclaimed-recipes',
  immediate: !!address.value,
  watch: [() => store.notificationUpdateVersion],
  onResponse: async ({ response }) => {
    if (!address.value) return;

    const thisBuildingRecipes = response._data.filter(
      (recipe: any) => recipe.buildingHash === props.selectedBuilding.buildingHash
    );
    const uniqueRecipes = thisBuildingRecipes.filter(
      (recipe: any, index: any, self: any) => self.findIndex((r: any) => r.recipeId === recipe.recipeId) === index
    );

    craftsAvailableForClaim.value = await getUserActivitiesShort(uniqueRecipes, address.value);
  }
});

provide('refreshUnclaimed', refreshUnclaimed);

watch(
  [recipes, address],
  (newValues) => {
    const [newRecipes] = newValues;

    if (newRecipes) {
      selectedRecipeId.value = newRecipes[0]?.recipeId;
    }
  },
  { immediate: true }
);

watch(
  [selectedRecipeId, recipes, address, () => store.notificationUpdateVersion],
  async (newRecipeId) => {
    if (!newRecipeId || !recipes.value || !address.value || !craftingContract) return;

    const userActivities = await getUserActivities(
      recipes.value,
      props.selectedBuilding,
      address.value,
      craftingContract
    );
    userActivityRef.splice(0, 4, ...userActivities);
  },
  {
    immediate: true
  }
);

watch(
  [recipes, address],
  () => {
    if (!recipes.value || !recipes.value.length) return;

    handleSelectItemToCraft(recipes.value[0].recipeId, 0);
  },
  { immediate: true }
);

const currentRecipe = computed(() => recipes.value?.find((recipe) => recipe?.recipeId === selectedRecipeId.value));
provide('currentRecipe', currentRecipe);

const recipeIdPrefix = computed(() => (config?.public.env === 'prod' ? 'prodRecipeId' : 'testnetRecipeId'));
const isNotLocal = computed(() => config?.public.env === 'prod' || config?.public.env === 'test');
const computedSocketRef = computed(() =>
  `${apiUrl}/${`ws/crafting/viewers?buildingId=${props.selectedBuilding.buildingHash}&recipeId=${selectedRecipeId.value}`}`.replace(
    'https',
    isNotLocal.value ? 'wss' : 'ws'
  )
);

const socketUrl = computedSocketRef.value;

const socket = new WebSocket(socketUrl);
socketRef.value = socket;

socket.onopen = () => {
  socket.send('connected');
};

socket.onmessage = ({ data }: any) => {
  const parsedData = JSON.parse(data);
  activeUsersModal.value = parsedData;
};

socket.onclose = () => {
  console.log('closing socket');
};

if (document.hidden) {
  socket.close();
}

onBeforeUnmount(() => {
  socket.close();
});

const handleScrollRecipes = (isNext: boolean) => {
  craftingListDOMRef.value?.scroll({
    left: isNext ? scrollLeftRef.value + 150 : scrollLeftRef.value - 150,
    behavior: 'smooth'
  });
};

const tokenMetadataComputed = computed(() => {
  if (!currentRecipe.value?.products.length) return null;

  if (currentRecipe.value) {
    return tokenMetaData(blockchain.contracts, currentRecipe.value?.products[0].tokenAddress);
  }
  return null;
});

const tokenUniqueness = computed(() => {
  if (!currentRecipe.value) return '';

  const product = currentRecipe.value?.products[0];
  const value = getNftValueByAddress(blockchain.contracts, product);

  return value;
});

const isNft = computed(() => {
  if (!currentRecipe.value) return false;

  return (
    currentRecipe.value &&
    currentRecipe.value.products.every(
      (product: RecipeProduct) => product.nftValueMin !== null && product.nftValueMax !== null
    )
  );
});

function tokenTokenUniquenessFormatter(key: TokenUniquenessType | string): string {
  switch (key) {
    case TokenUniquenessType.Common:
      return t('coreCommon');
    case TokenUniquenessType.Uncommon:
      return t('coreUncommon');
    case TokenUniquenessType.Rare:
      return t('coreRare');
    default:
      return '';
  }
}

function setProductMultiplier(multiplier: number) {
  craftingMultiplier.value = multiplier;
}

function setCraftingTime(formattedTime: string) {
  craftingTime.value = formattedTime;
}

async function refreshList() {
  if (!recipes.value || !address.value || !craftingContract) return;

  return await getUserActivities(recipes.value, props.selectedBuilding, address.value, craftingContract);
}

function handleSelectItemToCraft(recipeId: string, index: number) {
  const currentRecipeImage = getTokenImg(index);
  setTokenImgToStorage(currentRecipeImage);
  selectedRecipeId.value = recipeId;
}

function getTokenImg(index: number): string {
  if (!recipes.value) return '';

  const img = $getIconToken(recipes.value[index].products[0], BigNumber(recipes.value[index].recipeId || 0).toNumber());

  return img;
}

function setTokenImgToStorage(img: string): void {
  store.setCraftingTokenImg(img);
}

function handleToggleConfirmationModal() {
  isConfirmationOpen.value = !isConfirmationOpen.value;
}

async function handleProduceItem(recipe: RecipeProps) {
  isItemCrafting.value = true;

  const provider = new BrowserProvider(walletProvider.value as any);
  const signer = await provider.getSigner();

  const craftingContract = new Contract(blockchain.contracts.crafting, abi.crafting, signer);

  const nonce = await craftingContract.getNonce(address.value);

  const digest = keccak256(
    ethers.AbiCoder.defaultAbiCoder().encode(
      ['address', 'uint256', 'uint256'],
      [blockchain.contracts.crafting, nonce, blockchain.networkId]
    )
  );

  try {
    const signature = await signer.signMessage(getBytes(digest));

    const { r, s, v } = ethers.Signature.from(signature);

    const produceTx = await sendContractMethod(
      {
        contract: 'crafting',
        address: blockchain.contracts.crafting,
        methodName: 'useRecipe',
        methodArguments: [
          props.selectedBuilding?.buildingHash,
          Number(selectedRecipeId.value ?? 0),
          craftingMultiplier.value,
          v,
          r,
          s
        ]
      },
      () => {
        confirmationNumberRef.value = confirmationNumberRef.value + 1;
      }
    );

    if (!produceTx?.hash) return;

    let description = `${tokenMetadataComputed.value?.methodName} ${tokenMetadataComputed.value?.name.contract !== Tokens.woodenHuts ? capitalizeFirstLetter(tokenUniqueness.value) : ''}`;

    if (!isNft.value) {
      const values = recipe?.products.map((product) => {
        if (new BigNumber(product.quantityMin).eq(product.quantityMax)) {
          return BigNumber(product.quantityMin).multipliedBy(craftingMultiplier.value).decimalPlaces(1).toNumber();
        }

        const minValue = new BigNumber(product.quantityMin).multipliedBy(craftingMultiplier.value).decimalPlaces(1);
        const maxValue = new BigNumber(product.quantityMax).multipliedBy(craftingMultiplier.value).decimalPlaces(1);

        return `${minValue}-${maxValue}`;
      });

      description = recipe?.products
        .map(
          (product, index) =>
            `${(values && values[index]) || ''} ${t(tokenMetaData(blockchain.contracts, product.tokenAddress || '').methodName)}`
        )
        .join(', ')
        .trim() as string;
    }

    saveHashToLocalStorage(`${t('craftingCraftingTab')} (${description})`, produceTx.hash);

    store.updateVersion();

    await produceTx?.wait();

    modifyHistoryHashStatus(produceTx.hash, 'Done');
    confirmationNumberRef.value = 0;
    store.updateVersion();

    const params = {
      recipeId: selectedRecipeId.value,
      transactionHash: produceTx.hash,
      userAddress: address.value,
      landBuildingHash: props.selectedBuilding?.buildingHash
    };

    await fetch(apiUrl + `/crafting/activity`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    });

    ElNotification.success({
      title: `${t('craftingCraftingTab')} (${craftingMultiplier.value} ${t(tokenMetadataComputed.value?.label || '')} ${tokenTokenUniquenessFormatter(tokenUniqueness.value)})`,
      message: ''
    });
  } catch (error) {
    console.error(error);
    ElNotification.error({
      title: t('craftingErrorWhileCrafting'),
      message: ''
    });
  } finally {
    isConfirmationOpen.value = false;
    isItemCrafting.value = false;
  }
}
</script>

<style lang="scss">
.el-dialog.crafting.produce {
  max-width: 850px;

  .el-dialog {
    &__body {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.crafting-wrapper {
  font-family: Grenze, sans-serif;
}

.claimable-tokens {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 17px;
  border-radius: 12px;
  border: 2px solid #1e353e;
  background: linear-gradient(180deg, #60ff7c 0%, #04202b 100%);
  max-width: 600px;
  margin: 0 auto;

  .ready-for-claim-item {
    margin-right: 10px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
}

.claimable-blocks-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.ready-for-claim {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;

  @media screen and (max-width: 767px) {
    max-height: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 900px) {
    width: max-content;
  }

  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    flex: 1 1 auto;

    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      justify-items: center;
    }

    .ready-for-claim-item {
      display: flex;
      align-items: center;

      span {
        margin-bottom: 0;
        margin-left: 8px;
        font-weight: 800;
        font-size: 24px;
        color: #fff;
        text-wrap: nowrap;
      }

      .crafting-item-figure {
        width: 44px;
        height: 44px;
        min-height: 44px;
        min-width: 44px;
        display: flex;
        align-items: center;
        margin: 0;
        font-family: Eczar, sans-serif;

        img {
          width: 100%;
        }

        img.nftBackground {
          border-radius: 12px;
          background-color: #041a27;
          border: solid 1px #1e353e;
        }
      }
    }
  }

  button {
    height: 40px;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: clamp(14px, 2vw, 16px);

    svg {
      margin-left: 8px;
      path {
        transition: all 0.3s;
      }
    }

    &:hover {
      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }

  button:nth-child(2) {
    margin-left: 5px;
  }

  &-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
}

.crafting-item-figure {
  max-width: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  margin: 0;
  font-family: Eczar, sans-serif;

  p {
    margin-bottom: 0;
    margin-left: 5px;
    font-weight: 800;
    font-size: 24px;
    color: #fff;
    text-wrap: nowrap;
  }

  img {
    width: 100%;
  }
}

.crafting {
  &-header {
    position: relative;

    @media screen and (max-width: 767px) {
      position: static;
    }

    &-icons {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;

      &-stats,
      &-info {
        cursor: pointer;

        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;

        &:hover {
          opacity: 0.6;
        }
      }

      &-stats {
        background-image: url('~/public/img/icons/stats.svg');
      }

      &-info {
        background-image: url('~/public/img/icons/info.svg');
      }
      @media screen and (max-width: 900px) {
        top: 23px;
        left: 20px;
      }
    }
  }

  &-mobile-header {
    display: none;

    h2 {
      font-size: 40px;
      color: #fff;
      text-align: center;
    }

    @media screen and (max-width: 900px) {
      display: block;
    }
  }

  &-content {
    display: flex;
    margin-top: 16px;
    align-items: stretch;
    min-height: 420px;
    height: min-content;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    position: relative;

    & > img {
      max-width: 215px;
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      position: static;
    }
  }

  &-available-craft {
    position: relative;

    width: 100%;
    max-width: 195px;

    padding: 10px 7px;

    background: #0d242f;
    border: 1px solid #1e353e;
    border-radius: 15px;
    display: flex;
    flex-direction: column;

    &-title {
      color: #fff;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 0;
      display: block;

      &-mobile {
        display: none;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        color: #90989d;
        font-weight: 500;
        font-size: 18px;

        h3 {
          margin: 0 10px;
        }

        &-arrow {
          &:nth-child(1) {
            transform: rotate(180deg);
          }
          &.disabled {
            opacity: 0.3;
          }
        }
      }

      @media screen and (max-width: 900px) {
        display: none;

        &-mobile {
          display: flex;
        }
      }
    }

    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }

  &-list {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    transition: height 0.5s ease;

    @media screen and (max-width: 900px) {
      max-height: 65px;
      overflow-y: hidden;
      display: flex;
      overflow-x: auto;
      max-width: 100%;
    }
  }

  // .crafting-list-max-height-auto {
  //   max-height: 100%;

  //   @media screen and (max-width: 900px) {
  //     max-height: unset;
  //   }
  // }

  &-item {
    display: flex;
    align-items: center;
    padding: 8px 7px;
    height: 65px;

    @media screen and (max-width: 900px) {
      flex: 0 0 150px;
    }

    &.selectedItem {
      background: linear-gradient(180deg, #0083ad 0%, #00354f 100%), #081b25;
    }

    background: linear-gradient(190.74deg, #425b6a 7.97%, #0e1b25 95.05%);

    border: 1px solid #1e353e;
    border-radius: 12px;

    transition: all 0.3s ease;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 9px;

      @media screen and (max-width: 900px) {
        margin-top: 0;
        margin-left: 10px;
      }
    }

    &:hover {
      background: linear-gradient(180deg, #0083ad 0%, #00354f 100%), #081b25;
    }

    &-figure {
      width: 44px;
      height: 44px;
      min-height: 44px;
      min-width: 44px;
      display: flex;
      align-items: center;
      margin: 0;
      font-family: Eczar, sans-serif;

      img {
        width: 100%;
        border-radius: 8px;
      }

      p {
        margin-bottom: 0;
        margin-left: 5px;
        font-weight: 800;
        font-size: 24px;
        color: #fff;
        text-wrap: nowrap;
      }
    }

    &-name {
      color: #fff;
      font-family: Eczar, sans-serif;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0px;
      margin-left: 10px;
      text-align: left;
      word-break: break-word;
      overflow-wrap: break-word;
      hyphens: auto;
      word-break: keep-all;

      @media screen and (max-width: 900px) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .ru-locale-name {
      font-weight: 500;
      font-size: 14px;
    }

    .de-locale-name {
      font-weight: 500;
      font-size: 14px;
    }
  }

  &-table {
    width: 100%;
    max-width: calc(100% - 180px);
    padding-left: 10px;

    @media screen and (max-width: 900px) {
      max-width: unset;
      padding-left: 0;
      padding-top: 10px;
    }
  }

  &-text {
    text-align: center;
    margin-bottom: 0;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &-title {
    color: #fff;

    font-weight: 700;
    font-size: 32px;

    margin: 0;
    margin-bottom: 15px;
  }

  &-description {
    color: #90989d;
    text-align: center;
    font-size: 20px;
    margin-bottom: 0;
    max-width: 670px;
    margin: auto;
  }

  &-materials {
    margin: 0;
    padding: 0;

    list-style: none;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
  }

  &-recipe {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }

  &-arrow {
    font-size: 32px;
    color: #88e1ff;
    margin: 0 10px;

    @media screen and (max-width: 900px) {
      transform: rotate(90deg);
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &-crafted {
    padding: 0px 11px;
    border: 1px solid #1e353e;
    border-radius: 12px;

    background: #041a27;
    box-shadow: 2px 4px 5px 0px #00000040;
    background: linear-gradient(180deg, #0083ad 0%, #00354f 100%);
  }

  &-material {
    position: relative;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    font-family: Eczar, sans-serif;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    background: #041a27;
    border: 1px solid #1e353e;
    border-radius: 12px;
    box-shadow: 2.08px 4.17px 5.21px 0px rgba(0, 0, 0, 0.25);

    &-img {
      height: 50px;
      width: 50px;
    }

    &-amount {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      color: #cfd3dc;
    }
  }

  .el-input-number {
    width: 130px;
    flex-shrink: 0;
    @media screen and (max-width: 500px) {
      width: 160px;
      height: 48px;

      &__decrease,
      &__increase {
        width: 32px;
      }
    }

    @media screen and (max-width: 376px) {
      width: auto;
      min-width: 120px;
    }

    .el-input {
      background: radial-gradient(59.02% 71.08% at 84.01% 85.82%, #040f14 0%, #081b25 100%) !important;

      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
      font-size: 18px;

      &__inner {
        color: #cfd3dc;
        --el-input-inner-height: 32px;
        @media screen and (max-width: 500px) {
          --el-input-inner-height: 32px;
        }
      }

      &__wrapper {
        background: radial-gradient(59.02% 71.08% at 84.01% 85.82%, #040f14 0%, #081b25 100%) !important;

        @media screen and (max-width: 500px) {
          padding: unset;
        }
        input {
          padding: 0;
        }
      }
    }
  }
}

.max-height {
  height: 90vh;
  overflow: hidden;

  @media (max-width: 479px) {
    height: 70vh;
    overflow-y: auto;
  }
}
</style>
