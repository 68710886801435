<template>
  <div>
    <CraftingRecipeStatus :ready-time="readyTime" />

    <div v-if="recipeRequestStatus !== 'pending'" class="crafting-recipe">
      <ul class="crafting-materials">
        <CraftingMaterial
          v-for="(item, i) of recipeToCraft?.ingredients"
          :key="i"
          :ingredient="item"
          :produce-amount="produceAmount"
          :placeholder-width-string="materialWidthPlaceholderString"
        />
      </ul>
      <div class="crafting-arrow">→</div>
      <div v-for="finalProduct of recipeToCraft?.products" :key="finalProduct.productId" class="crafting-crafted">
        <img v-if="isNft" class="random-nft" src="/img/icons/random-nft.svg" alt="nft-random" />
        <img class="crafted-item-img" :src="$getIconToken(finalProduct, BigNumber(recipeId).toNumber())" alt="" />
        <p v-if="isNft" class="valuable">
          {{ $t(`core${capitalizeFirstLetter($getProductUniqueness(finalProduct))}`) }}
        </p>
        <p v-if="!isNft">
          <span v-if="new BigNumber(finalProduct.quantityMin).eq(finalProduct.quantityMax)">
            {{ new BigNumber(finalProduct.quantityMax).multipliedBy(produceAmount || 0) }}
          </span>
          <span v-else>
            {{ new BigNumber(finalProduct.quantityMin).decimalPlaces(1).multipliedBy(produceAmount || 0) }}-{{
              new BigNumber(finalProduct.quantityMax).decimalPlaces(1).multipliedBy(produceAmount || 0)
            }}
          </span>
        </p>
      </div>
    </div>

    <div class="crafting-buttons">
      <div class="crafting-buttons-wrapper">
        <el-input-number
          v-model="produceAmount"
          :step="1"
          :step-strictly="true"
          :validate-event="false"
          :min="MINIMUM_CRAFTING_RECIPES"
          :disabled="!maxAmountAvailableForCraft"
          :max="maxAmountAvailableForCraft || 1"
          :value-on-clear="maxAmountAvailableForCraft || 1"
        />
        <button
          class="btn-outline btn-max"
          type="button"
          :disabled="isItemCraftingInjected || (maxAmountAvailableForCraft || 0) <= 0"
          @click.stop="setMaxProduceItem"
        >
          {{ $t('craftingMax') }} ({{ maxAmountAvailableForCraft }})
        </button>
      </div>

      <button
        class="btn-primary"
        :disabled="
          !address ||
          isItemCraftingInjected ||
          (maxAmountAvailableForCraft || 0) <= 0 ||
          produceAmount > (maxAmountAvailableForCraft || 0) ||
          !isEnoughTokens
        "
        @click="handleToggleConfirmationModalInjected && handleToggleConfirmationModalInjected()"
      >
        {{ $t('craftingProduce') }}
        <span v-if="confirmationNumberRefInjected && isItemCraftingInjected"
          >{{ confirmationNumberRefInjected }} / {{ blockchain.minConfirmationsCount }}</span
        >
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject, watch } from 'vue';
import { CraftingMaterial } from '#components';
import { BigNumber } from 'bignumber.js';
import useEnvs from '~/composables/useEnvs';
import type { BuildingProps, RecipeProps, RecipeProduct, RunningRecipeProps } from '~/types/crafting';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { MINIMUM_CRAFTING_RECIPES } from '~/utils/constants';
import { useMainStore } from '~/stores/mainStore';
import CraftingRecipeStatus from '~/components/Crafting/CraftingRecipeStatus.vue';

const props = defineProps<{
  recipeId: string;
}>();
const { blockchain } = useEnvs();
const { availableTokens } = useTokensReader();
const { address } = useWeb3ModalAccount();
const store = useMainStore();

const building = inject<Ref<BuildingProps>>('building');
const confirmationNumberRefInjected = inject<number>('confirmationNumberRef');
const isItemCraftingInjected = inject<boolean>('isItemCrafting');
const recipeRequestStatus = inject<Ref<string>>('recipeRequestStatus');
const handleToggleConfirmationModalInjected = inject<() => void>('handleToggleConfirmationModal');
const setProductMultiplierInjected = inject<(multiplier: number) => void>('setProductMultiplier');
const userActivityItems = inject<any>('userActivity');
const queueTimeInjected = inject<Ref<number>>('queueTime');
const updateQueueTimeInjected = inject<() => void>('updateQueueTime');

const { data: recipeToCraft } = useNuxtData<RecipeProps>('recipes/recipe');
const { data: runningRecipes } = useNuxtData<RunningRecipeProps[]>('recipes/in-queue');

const maxAmountAvailableForCraft = inject<Ref<number>>('maxAmountAvailableForCraft');
const isEnoughTokens = inject<Ref<boolean>>('isEnoughTokens');

const readyTime = computed(() => {
  if (!queueTimeInjected) {
    return 0;
  }

  return BigNumber(queueTimeInjected.value)
    .plus(
      BigNumber(recipeToCraft.value?.duration || 0)
        .multipliedBy(produceAmount.value)
        .multipliedBy(1000)
    )
    .toNumber();
});

store.$onAction(async ({ name }) => {
  if (name !== 'updateVersion') {
    return;
  }

  setTimeout(async () => {
    await refreshNuxtData('recipes/in-queue');
  }, 2000);
});

const isNft = computed(() => {
  if (!recipeToCraft) return false;

  return (
    recipeToCraft.value &&
    recipeToCraft.value.products.every(
      (product: RecipeProduct) => product.nftValueMin !== null && product.nftValueMax !== null
    )
  );
});
const currentTime = Date.now();

const produceAmount = ref(1);
const materialWidthPlaceholderString = ref('');
const getRecipeTime = (time: string) => new Date(time).getTime();

const tokensForClaimRef = computed(
  () =>
    userActivityItems.flat().find((item: any) => {
      return (
        item.buildingHash === building?.value.buildingHash &&
        new Date(runningRecipe.value?.craftStartTime || 0)?.getTime() === item.date * 1000
      );
    })?.available || 0
);

const runningRecipe = computed(() => {
  return runningRecipes.value?.find((recipe) => {
    return (
      recipe.userAddress === address.value &&
      getRecipeTime(recipe.craftStartTime) <= currentTime &&
      currentTime <= getRecipeTime(recipe.craftEndTime)
    );
  });
});

watch(
  [
    recipeToCraft,
    address,
    tokensForClaimRef,
    () => recipeToCraft.value?.recipeId,
    () => runningRecipes.value?.length,
    () => isItemCraftingInjected
  ],
  async ([newRecipeToCraft, newAddress]) => {
    newRecipeToCraft?.ingredients.map(async (ingredient) => {
      const ingredientBalance = !newAddress ? 0 : await availableTokens(newAddress, ingredient.tokenAddress);

      const placeholderString = `${BigNumber(ingredientBalance).toFormat(0)} / ${BigNumber(ingredient.quantity)}`;
      if (placeholderString.length > materialWidthPlaceholderString.value.length) {
        materialWidthPlaceholderString.value = placeholderString;
      }
    });
  },
  { immediate: true }
);

watch(produceAmount, (newValue) => {
  setProductMultiplierInjected && setProductMultiplierInjected(newValue);
});

watch(
  () => props.recipeId,
  async () => {
    await refreshNuxtData('recipes/in-queue');

    produceAmount.value = 1;

    updateQueueTimeInjected && updateQueueTimeInjected();
  }
);

const setMaxProduceItem = () => {
  if (!maxAmountAvailableForCraft) {
    return;
  }
  produceAmount.value = maxAmountAvailableForCraft.value;
};
</script>

<style scoped lang="scss">
.crafting-crafted {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 16px;
  padding-bottom: 0px;

  p {
    margin-top: 5px;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 1rem;
    position: relative;
    top: -10px;
    color: #fff;
  }

  .valuable {
    color: #ffe604;
    font-size: 1.2rem;
    padding-top: 6px;
    text-transform: capitalize;
  }
}

.crafting-buttons {
  &-wrapper {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 479px) {
      align-items: center;
    }

    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }
  .amount {
    height: 50px;
  }

  .produce {
    padding: 10px 40px;

    @media screen and (max-width: 479px) {
      min-height: 52px;
    }
  }
}

.random-nft {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.crafted-item-img {
  padding-top: 3px;
  height: 70px;
  border-radius: 7px;
}

.btn-max {
  border-radius: 12px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 50px;
}

.crafting-info-duration-skeleton {
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>

<style lang="scss">
.crafting-buttons-wrapper .el-input-number {
  width: 130px;
  border: 1px solid #fff;
  border-radius: 12px;
  font-family: Eczar, sans-serif;
  font-weight: 400;

  &__decrease,
  &__increase {
    width: 32px;
    color: var(--main-text-color);
    font-size: 20px;
    font-weight: 700;
    background: linear-gradient(180deg, #0083ad 0%, #00354f 100%);
    top: 0;
    bottom: 0;
  }

  &__decrease {
    border-radius: 12px 0 0 12px;
    left: 0;
  }

  &__increase {
    border-radius: 0 12px 12px 0;
    right: 0;
  }

  .el-input {
    font-size: 18px;
    line-height: 1;
    background: radial-gradient(59.02% 71.08% at 84.01% 85.82%, #040f14 0%, #081b25 100%);
    overflow: hidden;
    border-radius: 12px;

    &__inner {
      --el-input-inner-height: 32px;
    }

    &__wrapper {
      border-radius: 12px;
      box-shadow: none;
      padding: 6px 11px;
    }
  }
}
</style>
